import React, { useState, useEffect } from "react";
import { DataGrid, RowId } from "@mui/x-data-grid";
import { GridRowId } from "@mui/x-data-grid";
import { makeStyles } from "@mui/styles";
import { UploadOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import {
  inventoryDeviceAction,
  transferDeviceAction,
  GetRMAReasonsAction,
  SubmitRMAAction,
  detailed_inventory_report_search,
  exportinventoryreportlist,
} from "../../../store/actions/DeviceActions";
import { connect, useDispatch } from "react-redux";
import Chip from "@mui/material/Chip";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";

import _ from "lodash";
import {
  Drawer,
  Space,
  Input,
  Col,
  Form,
  Row,
  Select,
  Button,
  message,
  Modal,
} from "antd";

const { Option } = Select;
const { confirm } = Modal;

const Inventory = (props) => {
  const [loadingEPbtn, setLoadingEPbtn] = useState(false);
  const [pagesize, setpagesize] = useState(10);
  const [open, setOpen] = React.useState(false);
  const [imei, setimei] = useState([]);
  const [carton, setcarton] = useState([]);
  const [IMEIOpen, setIMEIOpen] = useState(false);
  const [form] = Form.useForm();
  const [imeiList, setImeiList] = useState([]);
  const [rmaReasons, setRmaReasons] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);
  const [sbtnLoading, setSBtnLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [keyword, setkeyword] = useState("");
  const [selectionModel, setSelectionModel] = React.useState([]);

  const dispatch = useDispatch();
  useEffect(() => {
    setLoading(true);
    console.log(props.device);

    dispatch(inventoryDeviceAction())
      .then((res) => {
        console.log(res);
        setLoading(false);
      })
      .catch((ex) => {
        setLoading(false);
      });
    dispatch(GetRMAReasonsAction())
      .then((res) => {
        setRmaReasons(res);
      })
      .catch((ex) => {
        console.log(`error fetching RMA reasons`, ex);
        setLoading(false);
      });
  }, [dispatch]);

  const useStyles = makeStyles({
    grid: {
      fontFamily: "poppins, sans-serif !important",
    },
  });
  const classes = useStyles();

  const renderReasons = () => {
    return _.map(rmaReasons, (reason, index) => {
      return (
        <Option value={reason.id} key={index}>
          {reason.name}
        </Option>
      );
    });
  };

  const onRowsSelectionHandler = (ids, selectedrow) => {
    console.log(ids);
    setimei(ids);
    setcarton(selectedrow);
    setSelectionModel(selectedrow);
  };

  const handletransfer = () => {
    setBtnLoading(true);
    setLoading(true);
    var obj_arr = {
      imei: imei.toString(),
      manager_id: carton[0].manager_id,
      transfer_count: imei.length,
      reason: "Sample Reason",
      agent_job_id: 0,
      accept_transfer_status: 1,
    };
    console.log(obj_arr);
    dispatch(transferDeviceAction(obj_arr)).then((res) => {
      dispatch(inventoryDeviceAction());
      setOpen(false);
      setBtnLoading(false);
      setLoading(false);
    });
  };
  const columns = [
    {
      field: "imei",
      headerName: "IMEI",
      headerClassName: "bold-text",
      width: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "device_age",
      headerName: "Age (Days)",
      headerClassName: "bold-text",
      width: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "device_type",
      headerName: "Device Type",
      headerClassName: "bold-text",
      width: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "status",
      headerName: "status",
      width: 200,
      sortable: false,
      headerAlign: "center",
      headerClassName: "bold-text",
      align: "center",
      renderCell: (params) => {
        return (
          <span>
            {params.row.status === 5 ? (
              <Chip label={"In Stock"} color="primary" />
            ) : null}
          </span>
        );
      },
    },
    {
      field: "assigned_date",
      headerName: "Assigned Date",
      headerClassName: "bold-text",
      width: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "carton",
      headerName: "Carton Number",
      headerClassName: "bold-text",
      width: 200,
      headerAlign: "center",
      align: "center",
    },
  ];

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const showDrawer = () => {
    setIMEIOpen(true);
    setImeiList(imei);
    // var tempRmaList = [];
    imei.map((imei_item, index) => {
      return form.setFieldsValue({
        [`imei_${index}`]: imei_item,
        [`reason1_${index}`]: "",
        [`reason2_${index}`]: "",
        [`notes_${index}`]: "",
      });
      // return tempRmaList.push({ [`imei_${index}`]: imei_item, 'reason1': "", 'reason2': "", "notes": "" })
    });
    // setRmaList(tempRmaList);
  };
  const onClose = () => {
    confirm({
      icon: <ExclamationCircleOutlined />,
      title: "Are you sure?",
      content: "You want to cancel initiating RMA?",
      onOk: () => {
        setIMEIOpen(false);
        setImeiList([]);
        setimei([]);
        setcarton([]);
        resetform();
        setSelectionModel([]);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const resetform = () => {
    form.resetFields();
    imei.map((imei_item, index) => {
      return form.setFieldsValue({
        [`imei_${index}`]: imei_item,
        [`reason1_${index}`]: "",
        [`reason2_${index}`]: "",
        [`notes_${index}`]: "",
      });
      // return tempRmaList.push({ [`imei_${index}`]: imei_item, 'reason1': "", 'reason2': "", "notes": "" })
    });
  };

  const onFinish = (values) => {
    console.log("Success:", values);
    setBtnLoading(true);
    setLoading(true);

    var imei_list = [];
    imei.map((item, index) => {
      return imei_list.push({
        imei: values[`imei_${index}`],
        reason: values[`reason1_${index}`],
        reason_two: values[`reason2_${index}`],
        notes: values[`notes_${index}`],
      });
    });
    console.log("imei_list", imei_list);
    dispatch(SubmitRMAAction({ imei_list: imei_list }))
      .then((res) => {
        console.log(`response of RMA submit`, res);
        setIMEIOpen(false);
        setImeiList([]);
        setimei([]);
        setBtnLoading(false);
        setLoading(false);
        dispatch(inventoryDeviceAction());
      })
      .catch((err) => {
        //setDropzoneKey(dropzoneKey + 1);
        setIMEIOpen(false);
        setImeiList([]);
        setimei([]);
        setBtnLoading(false);
        setLoading(false);
      });
  };

  const onFinishFailed = (values) => {
    console.log("Failed:", values);
  };
  const clearSearch = () => {
    setLoading(true);
    setkeyword("");
    dispatch(inventoryDeviceAction())
      .then((res) => {
        console.log(res);
        setLoading(false);
      })
      .catch((ex) => {
        setLoading(false);
      });
  };
  const searchonboardingreport = () => {
    setSBtnLoading(true);
    setLoading(true);
    var search_text = keyword;

    dispatch(
      detailed_inventory_report_search({
        search_text: search_text,
      })
    )
      .then((res) => {
        console.log(res);
        setLoading(false);
        setSBtnLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setSBtnLoading(false);
      });
  };
  const getexportbtn = () => {
    setLoading(true);
    setLoadingEPbtn(true);
    var search_text = keyword;

    const obj = {
      search_text: search_text,
    };
    console.log(obj);
    dispatch(exportinventoryreportlist(obj))
      .then((res) => {
        setLoading(false);
        setLoadingEPbtn(false);
        if (res.data.download === 0) {
          message.success(res.data.message);
        } else {
          window.open(res.data.url, "_blank");
        }
      })
      .catch((err) => {
        setLoading(false);
        setLoadingEPbtn(false);
      });
  };
  return (
    <div className="row">
      <div className="col-xl-12">
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">
              <strong>Inventory List</strong>
            </h4>

            <div className="float-end">
              <Button
                style={{ marginRight: "10px" }}
                type="primary"
                className="mr5"
                loading={loadingEPbtn}
                onClick={getexportbtn}
              >
                <UploadOutlined style={{ fontSize: "14px" }} /> Export Report
              </Button>

              {imei.length !== 0 && (
                <button
                  onClick={handleClickOpen}
                  type="submit"
                  className="btn btn-primary "
                  style={{ marginRight: "10px" }}
                >
                  Transfer
                </button>
              )}
              {imei.length !== 0 && (
                <button
                  onClick={() => showDrawer()}
                  className="btn btn-primary"
                >
                  Initiate RMA
                </button>
              )}
              {/* {imei.length === 1 && (
                <Link to={"/rmareport?imei=" + imei}>
                  <button type="submit" className="btn btn-primary">
                    Initiate RMA
                  </button>
                </Link>
              )} */}
            </div>
          </div>

          <div className="card-body">
            <div className="row mt-1 mb-2" style={{ padding: "15px" }}>
              <div className="col">
                <Input
                  placeholder="Search by keyword"
                  value={keyword}
                  // ref={(node) => {
                  //   this.searchInput = node;
                  // }}
                  onChange={(e) => setkeyword(e.target.value)}
                  style={{ width: "100%", display: "block" }}
                />
              </div>
              <div className="col">
                <Button
                  type="primary"
                  className="mr5"
                  loading={sbtnLoading}
                  onClick={() => searchonboardingreport()}
                >
                  Search
                </Button>
                <Button
                  style={{ marginLeft: "10px" }}
                  type="default"
                  onClick={clearSearch}
                >
                  Clear
                </Button>
              </div>
            </div>
            <div style={{ width: "100%" }}>
              <div style={{ display: "flex", height: "100%" }}>
                <div style={{ flexGrow: 1 }}>
                  <DataGrid
                    sx={{
                      ".MuiDataGrid-columnSeparator": {
                        display: "none",
                      },
                    }}
                    loading={loading}
                    autoHeight
                    disableColumnMenu
                    getRowId={(row) => row.imei}
                    rows={props.device}
                    className={classes.grid}
                    columns={columns}
                    pageSize={pagesize}
                    rowsPerPageOptions={[pagesize]}
                    disableSelectionOnClick
                    checkboxSelection
                    // selectionModel={setcarton}
                    onSelectionModelChange={(ids) => {
                      console.log(ids);
                      const selectedIDs = new Set(ids);
                      const selectedRows = props.device.filter((row) =>
                        selectedIDs.has(row.imei)
                      );
                      onRowsSelectionHandler(ids, selectedRows);
                      console.log(ids, selectedRows);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to transfer ? "}
        </DialogTitle>

        <DialogActions>
          <Button onClick={handleClose}>No</Button>
          <Button loading={btnLoading} onClick={handletransfer} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Drawer
        width={680}
        onClose={onClose}
        open={IMEIOpen}
        bodyStyle={{
          paddingBottom: 80,
        }}
      >
        <Form
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          name="basic"
          layout="vertical"
        >
          <>
            {imeiList.map((item, index) => {
              return (
                <>
                  <Row gutter={16}>
                    <Col span={24}>
                      <Form.Item
                        name={`imei_${index}`}
                        label={`IMEI ${index + 1}`}
                        key={index}
                        value={item}
                        disabled={true}
                        rules={[
                          {
                            required: true,
                            message: "Please input imei",
                          },
                        ]}
                      >
                        <Input disabled={true} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item
                        label="Reason 1"
                        name={`reason1_${index}`}
                        rules={[
                          { required: true, message: "Please select reason" },
                        ]}
                      >
                        <Select
                          showSearch
                          style={{ width: "100%" }}
                          placeholder="Select Reason"
                          // onChange={reason1OnChange}
                          filterOption={(input, option) =>
                            option.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {renderReasons()}
                          {/* <Option value="AL">Alabama</Option>
                        <Option value="AK">Alaska</Option>
                        <Option value="AZ">Arizona</Option>
                        <Option value="AR">Arkansas</Option>
                        <Option value="CA">California</Option>
                        <Option value="CO">Colorado</Option>
                        <Option value="CT">Connecticut</Option>
                        <Option value="DE">Delaware</Option>
                        <Option value="DC">District Of Columbia</Option> */}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="Reason 2"
                        name={`reason2_${index}`}
                        rules={[
                          { required: false, message: "Please select reason" },
                        ]}
                      >
                        <Select
                          showSearch
                          style={{ width: "100%" }}
                          placeholder="Select Reason"
                          // onChange={reason2OnChange}
                          filterOption={(input, option) =>
                            option.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {renderReasons()}
                          {/* <Option value="AL">Alabama</Option>
                        <Option value="AK">Alaska</Option>
                        <Option value="AZ">Arizona</Option>
                        <Option value="AR">Arkansas</Option>
                        <Option value="CA">California</Option>
                        <Option value="CO">Colorado</Option>
                        <Option value="CT">Connecticut</Option>
                        <Option value="DE">Delaware</Option>
                        <Option value="DC">District Of Columbia</Option> */}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={24}>
                      <Form.Item
                        label="Comment"
                        name={`notes_${index}`}
                        rules={[
                          {
                            required: false,
                            // pattern: new RegExp(/^[0-9]+$/),
                            message: "Please input comment",
                          },
                        ]}
                      >
                        <Input.TextArea rows={2} placeholder="Enter comment" />
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              );
            })}
          </>
          <Form.Item
            wrapperCol={{
              offset: 0,
              span: 16,
            }}
          >
            <Space>
              {" "}
              <Button type="primary" loading={btnLoading} htmlType="submit">
                Submit
              </Button>
              <Button type="dashed" onClick={resetform}>
                Reset
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Drawer>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    device: state.devicelist.inventory,
  };
};

export default connect(mapStateToProps)(Inventory);
