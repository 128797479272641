import axiosInstance from "../services/AxiosInstance";
import axiosRootInstance from "./AxiosRootInstance";

export function getPosts() {
  return axiosInstance.get("/agentdashboard");
}
export function getDevice() {
  return axiosInstance.get("/agentassignlist");
}

export function createPost(postData) {
  return axiosInstance.post(`posts.json`, postData);
}

export function updatePost(post, postId) {
  return axiosInstance.put(`posts/${postId}.json`, post);
}
export function getHomeStat() {
  return axiosRootInstance.get("/api/v1/get_sales_dashboard_data");
}
export function deletePost(postId) {
  return axiosInstance.delete(`posts/${postId}.json`);
}

export function formatPosts(postsData) {
  let posts = [];
  for (let key in postsData) {
    posts.push({ ...postsData[key], id: key });
  }

  return posts;
}
