import React, { useEffect, useState } from "react";
import { get_app_version_info } from "../../store/actions/DeviceActions";
import { useDispatch } from "react-redux";

const Footer = () => {
  var d = new Date();
  const dispatch = useDispatch();
  const [appVersion, setAppversion] = useState("");
  useEffect(() => {
    dispatch(get_app_version_info())
      .then((res) => {
        console.log(res.data[0]);
        setAppversion(res.data[0]);
      })
      .catch((err) => {});
  }, []);
  return (
    <div className="footer">
      <div className="copyright">
        <p>
          App Version:&nbsp;{appVersion.application_version}
          {/* Copyright © Designed &amp; Developed by{" "}
          <a href="https://www.infinitelinked.com/" target="_blank" rel="noreferrer">
            Infinitelinked Solutions Pvt Ltd
          </a>{" "}
          {d.getFullYear()} */}
        </p>
      </div>
    </div>
  );
};

export default Footer;
