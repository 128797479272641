import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { Tree, Input } from "antd";
import { DownOutlined } from "@ant-design/icons";
// import Data from "./trainingDocument.json";
const { TreeNode } = Tree;
const { Search } = Input;

function TrainingComponent() {
  const user = useSelector((state) => state.user);
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [autoExpandParent, setAutoExpandParent] = useState(true);
  const [filteredData, setFilteredData] = useState([]);
  const [superadmin, setSuperadmin] = useState();
  const [masteruser, setMasterUser] = useState();
  const [retailer, setRetailer] = useState();
  const [ibo, setIbo] = useState([]);
  const treeRef = useRef(null);
  const treeNodesRef = useRef([]);
  const Data = [
    {
      category: "Agent",
      data: [
        {
          id: 1,
          isCollapsed: true,
          title: "Dashboard",
          content: "Agent Dashboard Training Module",
          link: "https://wieserp.s3.amazonaws.com/Training_Documents/Agent+Dashboard.pptx",
        },
        {
          id: 2,
          isCollapsed: true,
          title: "Inventory RMA",
          content: "Agent Inventory RMA Training Module",
          link: "https://wieserp.s3.amazonaws.com/Training_Documents/Agent+Inventory+_+RMA.pptx",
        },
        {
          id: 3,
          isCollapsed: true,
          title: "Sales Report",
          content: "Agent Sales Report Training Module",
          link: "https://wieserp.s3.amazonaws.com/Training_Documents/Agent+Sales+Report.pptx",
        },
      ],
    },
  ];

  const onChange = (e) => {
    const { value } = e.target;
    setSearchValue(value);
    setAutoExpandParent(true);

    const filtered = Data.filter(
      (item) =>
        item.category.toLowerCase().includes(value.toLowerCase()) ||
        item.data.some(
          (subItem) =>
            subItem.title.toLowerCase().includes(value.toLowerCase()) ||
            subItem.content.toLowerCase().includes(value.toLowerCase())
        )
    );
    setFilteredData(filtered);
  };

  useEffect(() => {
    console.log(user);
    setSuperadmin(user?.role_id?.filter((role) => role === 10) ?? []);
    setMasterUser(user?.role_id?.filter((role) => role === 1) ?? []);
    setRetailer(user?.role_id?.filter((role) => role === 5) ?? []);
    setIbo(user?.role_id?.filter((role) => role === 4 || role === 8) ?? []);
  }, []);
  const onExpand = (expandedKeys) => {
    setExpandedKeys(expandedKeys);
    setAutoExpandParent(false);
  };
  const renderTreeNodes = (data) =>
    data.map((item) => {
      if (item.data) {
        return (
          <TreeNode
            style={{ fontSize: "18px", marginLeft: "20px", width: "auto" }}
            title={item.category}
            key={item.category}
            ref={(node) => treeNodesRef.current.push(node)}
          >
            {item.data.map((subItem) => (
              <TreeNode
                className="mx-3"
                key={subItem.id}
                title={
                  <div>
                    {/* <p
                      className="mx-3"
                      style={{
                        fontSize: "18px",
                        marginBottom: "10px",
                        color: "#7E775F",
                      }}
                    >
                      {subItem.title}
                    </p> */}
                    <a
                      href={subItem.link}
                      rel="noopener noreferrer"
                      style={{
                        fontSize: "18px",
                      }}
                    >
                      <p
                        style={{ margin: 0, padding: 5 }}
                        className="mx-3"
                        onClick={() => {
                          const newWindow = window.open(
                            subItem.link,
                            "_blank",
                            "noopener,noreferrer"
                          );
                          if (newWindow) newWindow.opener = null;
                        }}
                      >
                        {subItem.title}
                      </p>
                    </a>
                  </div>
                }
              />
            ))}
          </TreeNode>
        );
      }
      return <TreeNode title={item.title} key={item.id} />;
    });

  useEffect(() => {
    console.log(Data);
    console.log(retailer);
    console.log(ibo);
    const expandedKeys = Data.reduce((keys, item) => {
      if (
        item.category.toLowerCase().includes(searchValue.toLowerCase()) ||
        item.data.some(
          (subItem) =>
            subItem.title.toLowerCase().includes(searchValue.toLowerCase()) ||
            subItem.content.toLowerCase().includes(searchValue.toLowerCase())
        )
      ) {
        return [...keys, item.category];
      }
      return keys;
    }, []);
    setExpandedKeys(expandedKeys);
    scrollToClosestMatch();
  }, [searchValue]);

  const scrollToClosestMatch = () => {
    if (treeRef.current) {
      const nodes = treeNodesRef.current;
      let minDistance = Number.MAX_VALUE;
      let closestNode = null;
      nodes.forEach((node) => {
        const text =
          node.props.title.props.children[0].props.children.toLowerCase();
        const distance = text.includes(searchValue.toLowerCase())
          ? text.indexOf(searchValue.toLowerCase())
          : Number.MAX_VALUE;
        if (distance < minDistance) {
          minDistance = distance;
          closestNode = node;
        }
      });

      if (closestNode && closestNode.dom) {
        closestNode.dom.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
  };

  return (
    <div>
      <section className="section mb-5 pt-1 pb-4 bg-dark">
        <div className="container ">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-xl-7 text-center desc">
              <div className=" mb-1 text-white" style={{ fontSize: "2.5rem" }}>
                Training Documents
              </div>
            </div>
          </div>
        </div>
      </section>
      <Tree
        ref={treeRef}
        switcherIcon={<DownOutlined style={{ fontSize: "1.2rem" }} />}
        expandedKeys={expandedKeys}
        autoExpandParent={autoExpandParent}
        onExpand={onExpand}
      >
        {renderTreeNodes(filteredData.length > 0 ? filteredData : Data)}
      </Tree>
    </div>
  );
}

export default TrainingComponent;
