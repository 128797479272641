/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Spin, Avatar, List } from "antd";
import { useSelector } from "react-redux";
import FreshChat from "react-freshchat";
import { Link } from "react-router-dom";
import icon from "../../../images/apprenticeship.svg";
import TrainingComponent from "./TrainingComponent";

const SideMenuSupport = (props) => {
  const [loading, setLoading] = useState(false);
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    console.log("auth", auth);
  }, []);
  return (
    <>
      <Spin spinning={loading}>
        <div className="container">
          <section className="section mb-5">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-8 col-xl-7 text-center desc">
                  <h2 className="h1 mb-3">Hi! How can we help You?</h2>
                  <p className="mx-lg-8"></p>
                </div>
              </div>
            </div>
          </section>
          <section className="section pt-0">
            <div className="container">
              <div className="row gy-4 justify-content-center">
                <div className="col-sm-6 col-md-6 col-lg-4">
                  <div className="card animate_card">
                    <div className="card-body text-center ">
                      <div className="icon-lg bg-primary rounded-3 text-white">
                        <i className="fa fa-envelope image-size"></i>
                      </div>
                      <div className="ps-3 col">
                        <h4 className="h6 mb-2">
                          <a
                            className="stretched-link text-reset"
                            href="mailto:lyvesupport@wiessolution.com"
                          >
                            Email Us
                          </a>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-4">
                  <div className="card animate_card ">
                    <div className="card-body text-center ">
                      <div className="icon-lg bg-primary rounded-3 text-white">
                        <i className="fa fa-id-badge image-size"></i>
                      </div>
                      <div className="ps-3 col">
                        <h5 className="h6 mb-2">
                          <a
                            href="tel:+18326622600"
                            className="stretched-link text-reset"
                            style={{ color: "white" }}
                          >
                            Call Us : 832-662-2600
                          </a>
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-4">
                  {/* Support Document */}
                  <div className="card animate_card">
                    <div className="card-body text-center">
                      <a
                        href={auth.auth.support_form_url}
                        target="_blank"
                        rel="noreferrer"
                        className="stretched-link text-reset"
                      >
                        <div className="icon-lg bg-primary rounded-3 text-white">
                          <i className="fa fa-life-ring image-size"></i>
                        </div>
                        <div className="ps-3 col">
                          <h5 className="h6 mb-2 " style={{ fontSize: "17px" }}>
                            Field Agent Support
                          </h5>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <div style={{ zIndex: 9999 }}>
            <FreshChat
              token="1d343824-7e42-4066-b691-86c418cf9252"
              host="https://wies-8e2a0e6df07116716990115.freshchat.com"
              email="support@wiessolution.com"
              first_name="Lavanya"
              open="true"
              onInit={(widget) => {
                widget.user.setProperties({
                  email: props?.auth?.name,
                  first_name: props?.auth?.name,
                  // last_name: "user.lastName",
                  // phone: "user.phoneNumber",
                });
              }}
            />
          </div> */}
        </div>
      </Spin>
    </>
  );
};

// const mapStateToProps = (state) => {
//   return {
//     auth: state.auth.auth,
//   };
// };

export default SideMenuSupport;
