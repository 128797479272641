import axios from "axios";
import { store } from "../store/store";

const API_URL =
  process.env.REACT_APP_URL || "https://dev-lyve-api.wiessolution.com";

const axiosAdmin = axios.create({
  baseURL: API_URL,
});

axiosAdmin.interceptors.request.use((config) => {
  const state = store.getState();
  const token = state.auth.auth.idToken;
  config.headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  return config;
});

export default axiosAdmin;
