import {
  getDevice,
  acceptDevice,
  formatError,
  inventoryDevice,
  transferDevice,
  scanDevice,
  pickupDevice,
  getTransaction,
  imageDevice,
  documentDevice,
  documentPostDevice,
  RmaReportDevice,
  RMAimageDevice,
  rmaReasons,
  RMADeviceSend,
  exportRmaReportDevice,
  RmaDeviceStatus,
} from "../../services/DeviceService";
import {
  GET_DEVICE_ACTION,
  ACCEPT_DEVICE_ACTION,
  INVENTORY_DEVICE_ACTION,
  TRANSFER_DEVICE_ACTION,
  PICKUP_DEVICE_ACTION,
  SCAN_DEVICE_ACTION,
  GET_DEVICE_TRANSACTION,
  GET_IMAGE_ACTION,
  DOCUMENT_GET_ACTION,
  RMA_GET_ACTION,
} from "./DeviceTypes";
import axiosAdmin from "../../services/AxiosAdmin";
import axiosRootInstance from "../../services/AxiosRootInstance";

export function getDeviceAction() {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      getDevice()
        .then((response) => {
          console.log(response);
          dispatch(GetPostsAction(response.data.data));
          resolve(response.data.data);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  };
}
export function UploadimageAction(formData) {
  return (dispatch, getState) => {
    imageDevice(formData)
      .then((response) => {
        console.log(response);
        // dispatch(GetImageAction(response.data.data));
        formatError(response.data.message, true);
      })
      .catch((error) => {
        console.log(error);
        formatError(error.response.data.error, false);
      });
  };
}

export function UploadRMAAction(formData) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      RMAimageDevice(formData)
        .then((response) => {
          console.log(response);
          // dispatch(GetImageAction(response.data.data));
          formatError(response.data.message, true);
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
          formatError(error.response.data.error, false);
        });
    });
  };
}

export function getDeviceTransaction() {
  return (dispatch, getState) => {
    getTransaction()
      .then((response) => {
        console.log(response);
        dispatch(GetTransactionAction(response.data.data));
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function acceptDeviceAction(device) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      acceptDevice(device)
        .then((response) => {
          // console.log(response)
          formatError(response.data.message, true);
          dispatch(AcceptPostsAction(response.data.data));
          getDeviceAction();
          resolve(response.data.data);
        })
        .catch((error) => {
          formatError(error.response.data.error, false);
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function pickupDeviceAction(device) {
  return (dispatch, getState) => {
    pickupDevice(device)
      .then((response) => {
        // console.log(response)
        formatError(response.data.message, true);
        dispatch(AcceptPostsAction(response.data.data));
      })
      .catch((error) => {
        formatError(error.response.data.error, false);
        console.log(error);
      });
  };
}

export function scanDeviceAction(device) {
  return (dispatch, getState) => {
    scanDevice(device)
      .then((response) => {
        // console.log(response)
        formatError(response.data.message, true);
        dispatch(AcceptPostsAction(response.data.data));
      })
      .catch((error) => {
        formatError(error.response.data.error, false);
        console.log(error.response.data.error);
      });
  };
}

export function transferDeviceAction(device) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      transferDevice(device)
        .then((response) => {
          // console.log(response)
          formatError(response.data.message, true);
          dispatch(TransferPostsAction(response.data.data));
          resolve(response.data.data);
        })
        .catch((error) => {
          formatError(error.response.data.error, false);
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function inventoryDeviceAction(device) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      inventoryDevice(device)
        .then((response) => {
          //console.log(response)

          dispatch(inventoryPostsAction(response.data.data));
          resolve(response.data.data);
        })
        .catch((error) => {
          formatError(error.response.data.error, false);
          console.log(error);
        });
    });
  };
}

export function DocumentDeviceAction() {
  return (dispatch, getState) => {
    documentDevice()
      .then((response) => {
        //console.log(response)

        dispatch(DocumentGetAction(response.data.data));
      })
      .catch((error) => {
        formatError(error.response.data.error, false);
        console.log(error);
      });
  };
}

export function DocumentDevicePostAction(obj) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      documentPostDevice(obj)
        .then((response) => {
          //console.log(response)
          formatError(response.data.message, true);
          resolve(response.data.message);
        })
        .catch((error) => {
          formatError(error.response.data.error, false);
          console.log(error.response.data.error);
          reject(error.response.data.error);
        });
    });
  };
}

export function GetRMAReasonsAction() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      rmaReasons()
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          formatError(error.response.data.error, false);
          console.log(error.response.data.error);
          reject(error.response.data.error);
        });
    });
  };
}

export function SubmitRMAAction(formData) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      RMADeviceSend(formData)
        .then((response) => {
          console.log(response);
          // dispatch(GetImageAction(response.data.data));
          formatError(response.data.message, true);
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
          formatError(error.response.data.error, false);
        });
    });
  };
}

export function RmaReportAction(params) {
  return (dispatch, getState) => {
    console.log(params);
    return new Promise((resolve, reject) => {
      RmaReportDevice(params)
        .then((response) => {
          console.log(response);
          resolve(response.data);
          dispatch(GetRMAAction(response.data.data));
        })
        .catch((error) => {
          formatError(error.response.data.error, false);
          console.log(error.response.data.error);
          reject(error.response.data.error);
        });
    });
  };
}
export function exportRmaReportAction(params) {
  return (dispatch, getState) => {
    console.log(params);
    return new Promise((resolve, reject) => {
      exportRmaReportDevice(params)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          formatError(error.response.data.error, false);
          reject(error.response.data.error);
        });
    });
  };
}

export function RmaDeviceStatusAction() {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      RmaDeviceStatus()
        .then((response) => {
          console.log(response);
          resolve(response.data);
        })
        .catch((error) => {
          formatError(error.response.data.error, false);
          console.log(error.response.data.error);
          reject(error.response.data.error);
        });
    });
  };
}

export function GetPostsAction(devices) {
  return {
    type: GET_DEVICE_ACTION,
    payload: devices,
  };
}

export function GetRMAAction(devices) {
  return {
    type: RMA_GET_ACTION,
    payload: devices,
  };
}

export function GetImageAction(devices) {
  return {
    type: GET_IMAGE_ACTION,
    payload: devices,
  };
}
export function GetTransactionAction(devices) {
  return {
    type: GET_DEVICE_TRANSACTION,
    payload: devices,
  };
}

export function AcceptPostsAction(devices) {
  return {
    type: ACCEPT_DEVICE_ACTION,
    payload: devices,
  };
}

export function scanPostsAction(devices) {
  return {
    type: SCAN_DEVICE_ACTION,
    payload: devices,
  };
}

export function pickupPostsAction(devices) {
  return {
    type: PICKUP_DEVICE_ACTION,
    payload: devices,
  };
}

export function TransferPostsAction(devices) {
  return {
    type: TRANSFER_DEVICE_ACTION,
    payload: devices,
  };
}

export function inventoryPostsAction(devices) {
  return {
    type: INVENTORY_DEVICE_ACTION,
    payload: devices,
  };
}

export function DocumentGetAction(devices) {
  return {
    type: DOCUMENT_GET_ACTION,
    payload: devices,
  };
}

//Agent module Actions

export function getofficelistname() {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      axiosAdmin
        .get("/api/v1/reference/offices")
        .then((response) => {
          console.log(response);
          // dispatch(GetImageAction(response.data.data));

          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
          formatError(error.response.data.error, false);
        });
    });
  };
}
export function SalesSummaryReport() {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      axiosAdmin
        .get("/api/v1/get_agent_sales_summary_data")
        .then((response) => {
          console.log(response);
          // dispatch(GetImageAction(response.data.data));

          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
          formatError(error.response.data.error, false);
        });
    });
  };
}

export function detailed_sales_report_search(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosAdmin
        .get(
          `/api/v1/get_agent_sales_summary_data?sort_by=${
            params.sort_by ?? ""
          }&sort=${params.sort ?? ""}&summary_date_start=${
            params.date_range_start ?? ""
          }&summary_date_end=${params.date_range_end ?? ""}&page=${
            params.page ?? 1
          }`
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  };
}

export function exportsalesreportlist(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(params);
      axiosAdmin
        .get(
          `/api/v1/get_agent_sales_summary_data_export?keyword=${
            params.search_text ?? ""
          }&summary_date_start=${
            params.date_range_start ?? ""
          }&summary_date_end=${params.date_range_end ?? ""}`
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function detailed_inventory_report_search(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosAdmin
        .get(
          `/api/v1/agent/agentinventorylistv1?keyword=${
            params.search_text ?? ""
          }`
        )
        .then(function (response) {
          console.log(response);
          dispatch(inventoryPostsAction(response.data.data));
          resolve(response.data.data);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
          formatError(error.response.data.error, false);
        });
    });
  };
}
export function exportinventoryreportlist(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(params);
      axiosAdmin
        .get(
          `/api/v1/agent/agent_inventory_list_export?keyword=${
            params.search_text ?? ""
          }`
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function exportAllRmaReport(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(params);
      axiosAdmin
        .get(
          `/api/v1/agent/agent_rma_request_status_export?imei=${
            params.imei ?? ""
          }&comments`
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}
// Reset password link
export function get_app_version_info() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosRootInstance
        .get("/api/v1/get_app_version_info")
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}
