/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";

import { DataGrid } from "@mui/x-data-grid";
import { connect, useDispatch } from "react-redux";
import { getHomeStatAction } from "../../../store/actions/PostActions";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { forcepassword, logout } from "../../../store/actions/AuthActions";
import { Link } from "react-router-dom";
import { Spin } from "antd";
import hotLead from "../../../images/hot-lead.png";
import unqualified from "../../../images/unqualified.png";
import unassigned from "../../../images/unassigned.png";
import abandon from "../../../images/cold-lead.png";
import newLead from "../../../images/new-lead.png";
import openEnroll from "../../../images/open-enrollment.png";
import enrolled from "../../../images/enrolled.png";

const Home = (props) => {
  const { user, dashdata } = props;
  console.log(dashdata);
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [pagesize, setpagesize] = useState(10);
  const [open1, setOpen1] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [oldpass, setOldpass] = useState("");
  const [newpass, setNewpass] = useState("");
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);

  let errorsObj = { email: "" };
  const [errors, setErrors] = useState(errorsObj);

  useEffect(() => {
    dispatch(getHomeStatAction()).then((res) => {
      const dataArray = Object.entries(res.data.data);
      setData(dataArray);
      setLoading(false);
    });
  }, [dispatch]);

  useEffect(() => {
    console.log(user);
    if (user.password_changed === 0) {
      setOpen(true);
    }
  }, [user]);

  // console.log(props.dashdata.activation_count.daily);

  const onSubmit = (e) => {
    e.preventDefault();
    setBtnLoading(true);
    let error = false;
    const errorObj = { ...errorsObj };
    if (newpass !== oldpass) {
      console.log(oldpass);
      errorObj.email = "Password Mismatch";
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      return;
    }
    console.log(oldpass, newpass, user.idToken);
    dispatch(forcepassword(newpass, user.idToken))
      .then((res) => {
        console.log(res);
        setOpen(false);
        setBtnLoading(false);
        dispatch(logout(props.history));
      })
      .catch((err) => {
        console.log(err);
        setBtnLoading(false);
      });
    // history.push("/login");
  };
  const useStyles = makeStyles({
    grid: {
      fontFamily: "poppins, sans-serif !important",
    },
  });
  const classes = useStyles();
  const columns = [
    {
      field: "created_date",
      headerName: "Date & Time",
      headerClassName: "bold-text",
      width: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "message",
      headerName: "message",
      headerClassName: "bold-text",
      minWidth: 300,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      width: 250,
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      align: "center",
      renderCell: (cellValues) => {
        return (
          <span>
            <Link
              to="#"
              onClick={() => handleclick(cellValues)}
              className="btn btn-primary shadow btn-xs  me-1"
            >
              View Message
            </Link>
          </span>
        );
      },
    },
  ];
  const handleclick = (e) => {
    console.log(e.row);
    setData(e.row);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Spin spinning={loading}>
        <div className="row">
          {data?.map((items, index) => {
            const leadIcons = [
              unassigned,
              newLead,
              openEnroll,
              hotLead,
              abandon,
              unqualified,
              enrolled,
            ];
            const leadIcon = leadIcons[index % leadIcons.length];
            return (
              <div className="col-xl-3 col-xxl-3 col-lg-6 col-sm-6" key={index}>
                <Link
                  to={{
                    pathname: "/salesreport",
                  }}
                >
                  <div
                    className={` card gradient-${index}`}
                    // style={{ backgroundColor: color }}
                  >
                    <div className="card-body  p-3">
                      <div className="media">
                        <div className="media-body">
                          <h5 className="mb-3 text-white">{items[0]}</h5>
                          <h3 className="mb-3 text-white">{items[1]}</h3>
                        </div>
                        <span>
                          <img
                            style={{
                              height: "45px",
                              color: "#fff",
                            }}
                            src={leadIcon}
                            alt={`${leadIcon}_icon`}
                            className="text-white ms-auto"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              // <div className="col">
              //   <div
              //     className="card radius-10 border-start border-0 border-3"
              //     style={{ backgroundColor: color }}
              //   >
              //     <div
              //       className="card-body"
              //       style={{ minWidth: 262.69, minHeight: 161.69 }}
              //     >
              //       <div className="d-flex align-items-center">
              //         <div>
              //           <p className="fw-bold fs-5 color-white">{items[0]}</p>
              //           <h3 className="my-0 fs-4">{items[1]}</h3>
              //           <p className="mb-0 font-13 visible-none">
              //             +2.5% from last week
              //           </p>
              //         </div>
              //         {/* <div className="widgets-icons-2 rounded-circle bg-gradient-scooter text-white ms-auto">
              //               <i className="fa fa-cubes"></i>
              //             </div> */}
              //       </div>
              //     </div>
              //   </div>
              // </div>
            );
          })}
        </div>
      </Spin>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state.auth.auth,
    dashdata: state.posts.stats,
    device: state.devicelist.transaction,
  };
};

export default connect(mapStateToProps)(Home);
